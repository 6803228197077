import { graphql, useStaticQuery } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import React from 'react'
import MetaImage from '../../assets/images/product/developer-overview/meta.png'
import tag from '../../assets/images/tags/developer-overview-tag.svg'
import Layout from '../../components/Layout'
import IllustrationBlock from '../../components/blocks/IllustrationBlock'
import CTABlock from '../../components/blocks/CTABlock'
import ColumnsBlock from '../../components/blocks/ColumnsBlock'
import HeroBlock from '../../components/blocks/HeroBlock'
import LeadBlock from '../../components/blocks/LeadBlock'
import MosaicBlock from '../../components/blocks/MosaicBlock'
import QuoteBlock from '../../components/blocks/QuoteBlock'
import { FeaturedBlogArticles } from '../../components/changelog/FeaturedBlogArticles'
import Box from '../../components/primitives/Box'
import Blurb from '../../components/system/Blurb'
import { responsiveScale } from '../../styles/helpers'

export const query = graphql`
  {
    backgroundImage: file(
      relativePath: { eq: "images/product/developer-overview/background.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, quality: 99)
      }
    }
    lead: file(
      relativePath: { eq: "images/product/developer-overview/lead.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1100)
      }
    }
    mosaicLeft: file(
      relativePath: { eq: "images/product/developer-overview/balance.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 650)
      }
    }
    mosaicRight: file(
      relativePath: { eq: "images/product/developer-overview/all-work.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 650)
      }
    }
    blurb1: file(
      relativePath: { eq: "images/product/developer-overview/prs.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 438)
      }
    }
    blurb2: file(
      relativePath: { eq: "images/product/developer-overview/focus.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 438)
      }
    }
    blurb3: file(
      relativePath: {
        eq: "images/product/developer-overview/collaboration.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 438)
      }
    }
  }
`

const DevOverviewPage = () => {
  const data = useStaticQuery(query)

  return (
    <Layout
      title="Developer overview"
      variant="dark"
      isNew
      description="Improve the quality of engineering one-to-ones and career development discussions with visualizations that don’t reduce engineers to a number."
      metaImage={MetaImage}
    >
      <HeroBlock
        tag={tag}
        title={<>Bring healthy visibility into engineers’ work</>}
        content="Improve the quality of conversations between software engineers and their managers with clear data visualizations."
        backgroundImage={getImage(data.backgroundImage)!}
      />
      <LeadBlock
        heading="Say bye to leaderboards and stack ranking"
        content="The developer overview answers one question: what has this engineer worked on? No numbers or unfair comparisons between teammates — just an objective view of ongoing and completed work."
      />
      <Box>
        <IllustrationBlock
          paddingTop={responsiveScale(32)}
          paddingBottom={responsiveScale(32)}
          image={getImage(data.lead)!}
          noPlaceHolder
          rounded
          shadow
        />
      </Box>
      <QuoteBlock person="carsten" />
      <LeadBlock
        heading="Enabling higher-quality conversations"
        content="The developer overview in Swarmia gives managers and engineers shared context for better discussions."
      />
      <MosaicBlock
        title="A better way for managers to coach software engineers"
        content="Look at the work each engineer has done without trying to reduce it to a number. Use the visualizations to coach developers on their strong points and possible learning opportunities."
        image={getImage(data.mosaicLeft)!}
      />
      <MosaicBlock
        title="A better way for engineers to drive career development discussions"
        content="Zoom out of the day-to-day to see everything you’ve worked on, identify improvement areas, and advocate for yourself in performance reviews."
        image={getImage(data.mosaicRight)!}
        imageAlign="right"
      />
      <Box paddingBottom={responsiveScale(64)}>
        <CTABlock title="Ready to gain healthy visibility into the work of software engineers?" />
        <LeadBlock
          heading="What you’ll see in the developer overview"
          content="There are many ways to slice and dice the work of individual engineers. Here are the main ones you’ll find in the developer overview."
        />
        <ColumnsBlock
          paddingTop={responsiveScale(48)}
          paddingBottom={responsiveScale(64)}
          columns={[
            <Blurb
              key="1"
              title="All work from PRs to epics"
              text="Get a full picture of each engineers’ work, including planned and completed items."
              image={getImage(data.blurb1)}
            />,
            <Blurb
              key="2"
              title="Focus"
              text="See how engineers’ time is split between the investment categories you’ve set."
              image={getImage(data.blurb2)}
            />,
            <Blurb
              key="3"
              title="Collaborators"
              text="Look at the “working together” section to find out who to ask for 360 feedback."
              image={getImage(data.blurb3)}
            />,
          ]}
        />
        <QuoteBlock person="christian2" />
        <FeaturedBlogArticles
          heading="More from the swarmia blog"
          articles={[
            '/blog/bringing-visibility-into-a-developers-work/',
            '/blog/how-we-use-swarmia-at-swarmia/',
            '/blog/minimizing-noise-and-bias-in-developer-surveys/',
          ]}
          newStyles
        />
      </Box>
    </Layout>
  )
}

export default DevOverviewPage
